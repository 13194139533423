@media only screen and (orientation: portrait) {
  .page-breadcrumb {
    display: none;
  }

  .form-group {
    margin-bottom: 0rem;
  }

  .save-button {
    position: fixed;
    bottom: 1.5rem;
    right: 10px;
  }

  .card-body {
    padding: 0;
  }

  .HorizontalTable {
    display: none;
  }

  .card .card-title {
    margin-top: 1rem;
    margin-left: 0.70rem;
  }

  .font-16 {
    font-size: 0.8rem !important;
  }

  .font-14 {
    font-size: 0.7rem !important;
  }

  .form-control {
    font-size: 0.8rem !important;
  }

  .col-form-label {
    padding-bottom: 0;
    font-size: 0.7rem;
  }

  Label {
    font-size: 0.7rem;
    padding-top: calc(0.375rem + 1px);
  }

  .hide-mobile {
    display: none;
  }

  .hide-mobile {
    display: none;
  }
}

@media screen and (orientation: landscape) {
  .VerticalTable {
    display: none;
  }
}

ng-multiselect-dropdown {
  flex-grow: 1;
}

.multiselect-dropdown .dropdown-btn {
  border-radius: 0 !important;
  border: 1px solid #e9edf2 !important;
  background-color: #fff !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  min-height: calc(1.5em + 0.75rem + 7px) !important;
  flex-grow: 1;
}

.drop-fill {
  flex-grow: 1;
}

.input-group>.input-group-prepend>.input-group-text {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.btn-creautente {
  background-color: #00a085;
  margin:20px 20px 20px 0px;

}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Stile per la checkbox personalizzata */
.custom-checkbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}

.custom-checkbox label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked+label::before {
  background-color: #ffffff;
  border-color: var(--main-color);
}

.custom-checkbox label::after {
  content: '✔';
  display: none;
  position: absolute;
  font-size: 18px;
  color: rgb(54, 193, 54);
  transition: color 0.4s;

}

.custom-checkbox.cross label::after {
  content: '✘';
  display: none;
  position: absolute;
  font-size: 18px;
  color: rgb(193, 54, 54);
  transition: color 0.4s;

}

.custom-checkbox input[type="checkbox"]:checked+label::after {
  display: inline-block;
}

.custom-checkbox label:hover::before {
  border-color: var(--main-color);
}

.input-group-prepend {
  height: calc(1.5em + 0.75rem + 7px);
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contenuti:before {
  content: "\e901";

}

.icon-corsi:before {
  content: "\e902";

}

.icon-dashboard:before {
  content: "\e903";

}

.icon-delete:before {
  content: "\e904";
  color: #db0517;
  font-size: x-large;
}

.icon-edit:before {
  content: "\e905";
  color: #62c4e6;
  font-size: x-large;
}

.icon-eye:before {
  content: "\e906";
  color: #62c4e6;
  font-size: x-large;
}

.icon-pdf:before {
  content: "\e907";
  color: #db0517;
  font-size: x-large;
}

.icon-questionario:before {
  content: "\e908";
  font-size: x-large;
}

.icon-salva:before {
  content: "\e909";
  color: #FFFFFF;
}

.icon-utenti:before {
  content: "\e90a";
}

.icon-video:before {
  content: "\e90b";
  color: rgb(0, 0, 95);
  font-size: x-large;
}

#main-wrapper {
  .page-wrapper {
    background: #FFFFFF;
  }

  &[data-style="FortyFix"] {


    [class^="icon-"],
    [class*=" icon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-plus:before,
    .icon-add:before {
      font-family: 'icomoon' !important;
      content: "\e900";
    }
    .fa-save:before,
    .icon-salva:before{
      font-family: 'icomoon' !important;
      content: "\e909";
    }

    .icon-contenuti:before {
      content: "\e901";

    }

    .custom-btn {
      background-color: transparent;
      border: none;
      color: #62c4e6;
      font-weight: bold;
      font-size: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .custom-btn span {
      display: flex;
      align-items: center;
    }

    .custom-btn i {
      margin-left: 0.5rem;
      font-size: 1rem;
    }

    .custom-btn:hover {
      text-decoration: underline;
    }

    .completato {
      
      color: green;
    }

    .in-corso {
      
      color: yellow;
    }

    .iniziare {
      
      color: grey;
    }

    .domanda {
      background-color: #ccc;
    }

    .table tbody tr.row-highlight:hover {
      background-color: #f2f2f2 !important;
      transition: background-color 0.2s ease-in-out;
    }

    .questionario-button {
      display: block;
      border-radius: 10px;
      background-color: #ccc;
      text-align: center;
      margin-right: 0;
    }

    .tot {
      background-color: #62C4E6;
    }

    .domanda:focus {
      background-color: #ccc;
    }

    .questionario {
      font-family: 'icomoon' !important;
      content: "\e900";
      font-size: x-large;
      color: #00a085;
    }

    .Descrizione {
      display: block;
      width: 100%;
      height: auto;
      overflow-y: auto;
      padding: 8px;
      border: 20px solid #ccc;
      border-radius: 20px;
      line-height: 1.5;
      font-size: 14px;
      box-sizing: border-box;

    }
    .btn-editpersona{
      background-color:#00a085 !important ;
    }

    .icon-corsi:before {
      content: "\e902";

    }

    .icon-dashboard:before {
      content: "\e903";

    }

    .icon-delete:before {
      content: "\e904";
      color: #db0517;
      font-size: x-large;
    }

    .icon-edit:before {
      content: "\e905";
      color: #62c4e6;
      font-size: x-large;
    }

    .icon-eye:before {
      content: "\e906";
      color: #62c4e6;
      font-size: x-large;
    }

    .icon-pdf:before {
      content: "\e907";
      color: #db0517;
      font-size: x-large;
    }

    .icon-questionario:before {
      content: "\e908";
      font-size: x-large;
    }

    .icon-salva:before {
      content: "\e909";
      color: #FFFFFF;
    }

    .icon-utenti:before {
      content: "\e90a";
    }

    .icon-video:before {
      content: "\e90b";
      color: rgb(0, 0, 95);
      font-size: x-large;
    }

    .btn-cyan {
      background-color: #00a085;
    }


    .sidebar-nav ul .sidebar-item .sidebar-link.active,
    .sidebar-nav ul .sidebar-item .sidebar-link:hover {
      color: var(--input-text-color);

      i {
        color: var(--input-text-color);
      }
    }

    .topbar .top-navbar .navbar-header {
      background-color: var(--logo-bg);
    }

    .topbar .navbar-collapse,
    .topbar {
      background-color: var(--navbar-bg);
      border-bottom: 1px solid var(--navbar-accent-color);
    }

    .left-sidebar {
      background: var(--sidebar-bg);

      ul {
        background: var(--sidebar-bg);

        .create-btn {
          background: Black;
        }
      }
    }

    .breadcrumb-item {
      color: var(--main-color);
      font-size: 1.5rem;
      align-items: center;
      display: flex;

      a {
        color: var(--main-color);
        font-size: 1.5rem;
      }
    }

    .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: var(--main-color);
      font-size: 1rem;
    }

    .selected {
      background-color: #62C4E6;
      color: #ffffff;
    }


    .container-fluid {
      height: calc(100vh - 180px);
      min-height: calc(100vh - 400px);
      overflow-y: scroll;
      overflow-x: clip;
    }

    @media screen and (orientation: portrait) {
      .container-fluid {
        padding-block: 20px;
        padding-inline: 0;
      }
    }




    // table
    $radius: 1rem;

    .table {
      thead {
        background-color: transparent;

        tr {
          background: var(--main-color);

          th:nth-child(1) {
            padding-left: 3rem;
          }
        }

        th {
          border: none;
          color: #FFFFFF;
          text-transform: uppercase;
        }
      }

      tbody {
        tr:nth-child(1) {
          td {
            border-top: none;
          }
        }

        tr {
          td:nth-child(1) {
            padding-left: 2rem;
          }
        }
      }

      tbody:before {
        content: "@";
        display: block;
        line-height: 5px;
        background-color: transparent;
        text-indent: -99999px;
      }

    }

    .table.bg-white {
      background-color: transparent !important;

      tbody {
        tr {
          background-color: #FFFFFF;
        }
      }
    }

    table th:nth-child(1) {
      -webkit-border-radius: $radius 0 0 $radius;
      -moz-border-radius: $radius 0 0 $radius;
      border-radius: $radius 0 0 $radius;
    }

    table th:nth-last-child(1) {
      -webkit-border-radius: 0 $radius $radius 0;
      -moz-border-radius: 0 $radius $radius 0;
      border-radius: 0 $radius $radius 0;
    }


    //modale
    .modal-header {
      background-color: var(--main-color);
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;

      h5 {
        color: #ffffff;
        text-transform: uppercase;
      }

      button.close {
        color: #ffffff;
      }
    }

    .modal-body {
      background-color: transparent;
      padding: 2rem;

      // Styling per i tab all'interno della modale
      ul.nav-tabs {
        li {
          a {
            background-color: transparent;
            color: var(--main-color);
            text-transform: uppercase;
            padding: 0.5rem 1rem;

            &:hover {
              background-color: var(--main-color);
              color: #ffffff;
            }
          }
        }

        // Stile per il contenuto attivo dei tab
        li.ngbNavItem.active a {
          background-color: var(--main-color);
          color: #ffffff;
        }
      }

      .form-group {
        label {
          color: var(--main-color);
          text-transform: uppercase;
        }

        input,
        textarea,
        ng-multiselect-dropdown {
          border-radius: $radius;
        }
      }

      // Condizione che simula un "placeholder" per creare distacco visivo
      &:before {
        content: "@";
        display: block;
        line-height: 5px;
        background-color: transparent;
        text-indent: -99999px;
      }
    }

    .modal-footer {
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      background-color: #ffffff;

      button.btn-success {
        background-color: var(--main-color);
        border-radius: $radius;
        text-transform: uppercase;
        color: #ffffff;

        &:hover {
          background-color: (var(--main-color), 10%);
        }
      }
    }


    //input
    .form-control {
      border-radius: 0.5rem;
      border-color: #B3B3B3;
      border-width: 1px;
      color: var(--input-text-color);
    }

    .control-label {
      text-transform: uppercase;
      color: #666666
    }

    input[type=checkbox] {
      height: 1rem;
      accent-color: green;
    }


    // dropdown
    $base-color: transparent;
    $disable-background-color: #eceeef;

    .multiselect-dropdown {

      .dropdown-btn {

        border: 1px solid #B3B3B3;
        border-radius: 0.5rem;

        .selected-item-container {
          .selected-item {
            border: 1px solid $base-color;
            margin-right: 0;
            margin-bottom: 0;
            background: $base-color;
            color: var(--input-text-color);

            a {
              text-decoration: none;
              color: var(--input-text-color) !important;

              &:hover {
                color: var(--input-text-color) !important;
              }
            }
          }
        }

        .selected-item:hover {
          box-shadow: none;
        }

        .dropdown-multiselect__caret:before {
          color: var(--main-color);
          border-color: var(--main-color) transparent;
        }

        .dropdown-multiselect--active .dropdown-multiselect__caret {
          transform: rotateZ(180deg);
        }
      }

      .disabled {
        &>span {
          background-color: $disable-background-color;
        }
      }
    }

    .ciao-utente,
    .logout-button {
      span:hover {
        cursor: pointer;
      }

      .icon:hover {
        cursor: pointer;
      }
    }

    .imgcard {
      aspect-ratio: 1 / 1;
      background-color: #fff;
      border: 1px solid #737373;
      border-radius: 1rem;
      margin: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }

    .corsocard {
      border: 1px solid #737373;
      border-radius: 1rem;
      background-color: #CBD4AD;
    }

    .corsoinfo {
      margin-top: 0.1rem;
      margin-bottom: 0.3rem;
      background-color: #CBD4AD;
    }

    .table {
      width: 100%;
    }

    .expanded-row {
      background-color: #e6e6e6;
    }


    .lessons {
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 20px;
      margin-right: 20px;
      margin-left: 50px;
    }

    .lessons table {
      width: 100%;
      border-collapse: collapse;
    }

    .lessons table th,
    .lessons table td {
      padding: 8px;
      text-align: left;
      color: #666666;
    }

    .quadrato {
      height: 1.5rem;
      aspect-ratio: 1/1;
      background-color: var(--main-color);
      border-radius: 0.5rem;
      margin-left: 0.6rem;
      margin-right: 0.6rem;
    }

    .container {
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 20px;
      width: 100%;
      max-width: 900px;
      background-color: #f9f9f9;
    }

    .filter-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .search-input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 30%;
    }

    .category-select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .btn-add {
      padding: 10px 20px;
      background-color: #2ecc71;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .main-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .left-column,
    .right-column {
      flex: 1;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: white;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .controls button {
      padding: 8px 16px;
      border: none;
      background-color: #62C4E6;
      color: white;
      border-radius: 5px;
      cursor: pointer;
    }

    .user-list {
      max-height: 300px;
      overflow-y: auto;
    }

    .user-item {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      border-bottom: 1px solid #e6e6e6;
    }

    .transfer-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .transfer-controls button {
      font-size: 24px;
      background-color: #62C4E6;
      color: white;
      border: none;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }

    .alunni-box {
      background-color: #e6e6e6;
      border-radius: 8px;
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }



    .btn-save {
      padding: 10px 20px;
      background-color: #2ecc71;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.custom-modal-dialog .modal-content {
  background-color: rgb(203, 201, 201);
  border: 3px solid #62c4e6;
  border-radius: 40px;
  /* Bordi arrotondati */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  /* Ombra */
}

.footer {
  display: flex;
  justify-content: right;
  background-color: inherit;
}

.footer {
  border-top: 1px solid var(--main-color);
  padding: 0px 50px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: end;
  height: 50px;
  margin: 0px;
  align-items: center;
}

.status-icon {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.status-icon:hover .tooltiptext {
  visibility: visible;
}

/* Tooltip text */
.modal-tooltip-text {
  visibility: hidden;
  width: 35vw;
  background-color: white;
  color: #000;
  text-align: center;
  text-wrap-mode: wrap ;
  padding: 5px 10px;
  border-radius: 2px;
  border: 0.3px solid black;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.status-icon:hover .modal-tooltip-text {
  visibility: visible;
}

