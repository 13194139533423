@font-face {
  font-family: TitilliumWeb-SemiBold;
  src: url("../font/TitilliumWeb-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: TitilliumWeb-Bold;
  src: url("../font/TitilliumWeb-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?o8y6m');
  src: url('../fonts/icomoon.eot?o8y6m#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?o8y6m') format('truetype'),
    url('../fonts/icomoon.woff?o8y6m') format('woff'),
    url('../fonts/icomoon.svg?o8y6m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}